.side-navigation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
}

.side-buttons {
    padding-left: 1rem;
}

@media only screen and (max-width: 600px) {

    .side-navigation {
        flex-direction: row;
        display: none;
    }

    .side-buttons {
        padding: 0rem;
        display: flex;
        flex-direction: column;
    }

}