.profile-background {
    width: 100%;
    box-sizing: border-box;
    position: relative
}

.background-image {
    width: 100%;
    height: 12rem;
    border-radius: 0.5rem;
}

.profile-image {
    position: absolute;
    width: 6rem;
    height: 6rem;
    bottom: -1rem;
    left: 2rem;
    border-radius: 0.5rem;
    border: 2px solid var(--primary);
}