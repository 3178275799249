.auth-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    width: 18rem;
    gap: 0.8rem;
}

.avatar-box {
    width: 18rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.2rem;
    justify-content: center;
}

.avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.1rem;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    filter: brightness(50%);
    cursor: pointer;
    border: 1.5px solid transparent;
}

.avatar-img {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
}

.name-fields {
    display: flex;
    gap: 0.5rem;
}

.password-container {
    position: relative;
    width: 100%;
}

.password-icons {
    position: absolute;
    right: 0;
    top: 0.5rem;
}

input[type="radio"] {
    display: none;
}

.avatar:hover, .avatar-focus {
    filter: brightness(100%);
    border: 1.5px solid var(--primary);
}