.suggest-card {
    padding: 0 2rem 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    box-sizing: border-box;
}

.search {
    padding: 0.5rem;
    width: 100%;
    border-radius: 0.5rem;
    color: var(--primary);
    background-color: inherit;
}

.search-light {
    border: 1px solid var(--grey);
}

.search-dark {
    border: 1px solid var(--light-grey);
}

.profile-mapping {
    display: flex;
    flex-direction: column;
    gap: 0.5rem
}

.profile-card {
    display: flex;
    align-items: center;
    width: 17rem;
    gap: 0.5rem;
    justify-content: space-between;
    border: 1.2px solid var(--light-grey);
    border-radius: 0.5rem;
    padding: 0.2rem;
    box-sizing: border-box;
}

.search-card {
    border-radius: 0.5rem;
    border: 1px solid var(--black);
    padding: 0.1rem;
}

.search-text {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.list-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.search:focus {
    outline: 1.5px solid var(--primary-hover);
}